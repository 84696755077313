<template>
  <v-container fill-height class="wider">
    <OverlayComponent
      v-if="overlay.id"
      :width="overlay.width"
      :height="overlay.height"
      :edit="false" />
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';
import OverlayComponent from '@/components/OverlayComponent.vue';

export default {
  name: 'OverlayShowComponent',
  components: { OverlayComponent },
  data: () => ({
    overlay: {},
  }),
  mounted() {
    BackendService.loadOverlay(this.$router.currentRoute.params.id).then((data) => {
      this.overlay = data.data;
    });
  },
  methods: {
  },
};
</script>
<style>
.fill-most {
  height: calc(100% - 50px)
}

.wider {
  max-width: 99vw !important;
}
</style>
