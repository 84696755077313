import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.prototype.$wsurl = `ws://${process.env.VUE_APP_WS_URL}:${process.env.VUE_APP_WS_PORT}`;

// Vue.prototype.$wsurl = `wss://${process.env.VUE_APP_WS_URL}`;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
