<template>
  <v-container>
    <h1 class="text-center">Auto Nachrichten</h1>
    <v-toolbar flat>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-toolbar>
    <v-data-table :search="search" :headers="headers" :items="pools" sort-by="calories" class="elevation-1">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editieren!</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Löschen!</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Dialog für Content- und Pool-Einstellungen -->
    <v-dialog v-model="dialog" max-width="80vw">
      <v-card>
        <v-card-title class="justify-center">
          Einstellungen für {{ editedItem.name }}
        </v-card-title>
        <v-card-text>
          <!-- Pool-Einstellungen -->
          <v-row>
            <v-col>
              <v-text-field v-model="editedItem.name" label="Pool Name" outlined dense
                :rules="[v => !!v || 'Dieses Feld darf nicht leer sein!']" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="editedItem.interval_sec" label="Interval in sec" outlined dense type="Number"
                :rules="numberRules(editedItem.interval_sec)" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="editedItem.sendby" label="Alle x Nachrichten" type="Number" outlined dense
                :rules="numberRules(editedItem.sendby)" required></v-text-field>
            </v-col>
          </v-row>
          <!-- Content-Einstellungen -->
          <v-row v-for="(content, index) in filteredContent" :key="content.id">
            <v-col cols="10">
              <v-text-field v-model="content.content" label="Content" outlined dense
                :rules="[v => !!v || 'Dieses Feld darf nicht leer sein!']" required></v-text-field>
            </v-col>
            <v-col>
              <v-btn @click="removeContent(index)" class="error">Entfernen</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveSettings" class="success">Speichern</v-btn>
          <v-btn @click="addNewContent" class="primary ml-auto">Neuer Content</v-btn>
          <v-btn @click="dialog = false" class="error ml-auto">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col col="6">
        <v-btn @click="openNewPoolDialog" class="success">Neuer Pool</v-btn>
      </v-col>
      <v-col col="6" class="text-right">
        <v-btn @click="toggleText" class="success">How To</v-btn>
      </v-col>
    </v-row>

    <!-- Text Container -->
    <div class="custom-container3" v-if="showText">
      <p>
        Wie funktionieren Auto Nachrichten? <br><br>

        Man erstellt ein Pool, wo man mehrere Nachrichten einfügen kann, die dann
        je nach Einstellung (Zeitintervall, Anzahl von Nachrichten) wiedergegeben werden.<br><br>

        z. B. Pool "Socials"<br><br>

        - 1 Nachricht: Insta-Link<br>
        - 2 Nachricht: TikTok-Link<br>
        - 3 Nachricht: Facebook-Link<br><br>

        Der Pool lautet "Socials" und fasst 3 Nachrichten. Diese
        werden dann in Reihenfolge im Chat erscheinen, wenn die Bedingungen gegeben sind.<br><br>

        z.B. Zeitintervall auf 600 (10 Minuten) und es müssen in der Zeit 10 Nachrichten
        geschrieben werden, dann würde erst eine Auto Nachricht ausgegeben.<br><br>

        Wenn man nur ein Zeitintervall haben möchte, dann stellt man die Anzahl
        der Nachrichten auf 1 und das gewünschte Zeitintervall (in Sekunden) ein.<br><br>

        Wenn man nach einer bestimmten Anzahl von Nachrichten eine Auto Nachricht
        ausgeben möchte, dann die Anzahl der benötigten Nachrichten einstellen
        z.B. 20 und das Zeitintervall (in Sekunden) auf 1.<br><br>

        Man kann natürlich mehrere Pools einstellen, mit unterschiedlichen Bedingungen.
      </p>
    </div>

    <!-- Dialog für neuen Pool -->
    <v-dialog v-model="newPoolDialog" max-width="600px">
      <v-card>
        <v-card-title>
          Neuer Pool
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="newPoolName" label="Titel" outlined dense
            :rules="[v => !!v || 'Dieses Feld darf nicht leer sein!']" required>
          </v-text-field>
          <v-text-field v-model="newPoolInterval" label="Interval in sec" outlined dense
            :rules="numberRules(newPoolInterval)" required>
          </v-text-field>
          <v-text-field v-model="newPoolSendBy" label="Alle x Nachrichten" outlined dense
            :rules="numberRules(newPoolSendBy)" required>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createNewPool" class="success">Erstellen
          </v-btn>
          <v-btn @click="cancelNewPool" class="error ml-auto">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Sicher diesen Pool löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="CheckInput" timeout="4000" color="red">
      {{ errorCheckInput }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="CheckInput = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackendService from '@/services/BackendService';

export default {
  name: 'IntervalMsg',

  data: () => ({
    headers: [
      { text: 'Titel', value: 'name', align: 'center' },
      { text: 'Interval in sec', value: 'interval_sec', align: 'center' },
      { text: 'Alle x Nachrichten', value: 'sendby', align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    showText: false,
    pools: [],
    pool_content: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    dialog: false,
    newPoolDialog: false,
    dialogDelete: false,
    newPoolName: '',
    newPoolInterval: 1,
    newPoolSendBy: 1,
    search: '',
    inputNumber: null,
    error: false,
    check: true,
    CheckInput: false,
    errorCheckInput: 'Fehlerhafte Eingabe! Bitte Eingabe prüfen!',
  }),

  computed: {
    filteredContent() {
      return this.pool_content.filter((content) => content.pool_id === this.editedItem.id);
    },
  },

  mounted() {
    BackendService.loadPools().then((data) => {
      this.pools = data.data.pools;
      this.pool_content = data.data.pool_content;
    });
  },

  methods: {
    numberRules(value) {
      return [
        !!value || 'Das Feld darf nicht leer sein',
        (value > 0 && !Number.isNaN(value)) || 'Bitte gib eine Zahl größer als 0 ein',
      ];
    },
    toggleText() {
      this.showText = !this.showText; // Text an/aus schalten
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },

    addNewContent() {
      this.filteredContent.push({ content: '' });
      this.$forceUpdate();
    },

    removeContent(index) {
      this.$delete(this.filteredContent, index);
      this.$forceUpdate();
    },

    saveSettings() {
      if (this.editedItem.interval_sec > 0
        && this.editedItem.sendby > 0) {
        const poolsettings = {
          id: this.editedItem.id,
          name: this.editedItem.name,
          interval_sec: this.editedItem.interval_sec,
          sendby: this.editedItem.sendby,
        };
        BackendService.savePoolSettings(poolsettings).then(() => {
          BackendService.savePoolContent(this.editedItem.id, this.filteredContent)
            .then((data) => {
              this.pools = data.data.pools;
              this.pool_content = data.data.pool_content;
              this.dialog = false;
            })
            .catch((error) => {
              console.error('Fehler beim Speichern des Contents:', error);
            });
        }).catch((error) => {
          console.error('Fehler beim Speichern der Pool-Einstellungen:', error);
        });
      } else {
        this.CheckInput = true;
      }
    },

    openNewPoolDialog() {
      this.newPoolDialog = true;
    },

    cancelNewPool() {
      this.newPoolDialog = false;
      this.resetNewPoolFields();
    },

    resetNewPoolFields() {
      this.newPoolName = '';
      this.newPoolInterval = '';
      this.newPoolSendBy = '';
    },

    createNewPool() {
      if (!this.newPoolName || !this.newPoolInterval || !this.newPoolSendBy) {
        return;
      }
      const newPool = {
        name: this.newPoolName,
        interval_sec: parseInt(this.newPoolInterval, 10),
        sendby: parseInt(this.newPoolSendBy, 10),
      };

      BackendService.createPool(newPool)
        .then((data) => {
          this.pools = data.data.pools;
          this.pool_content = data.data.pool_content;
          this.newPoolDialog = false;
          this.resetNewPoolFields();
        })
        .catch((error) => {
          console.error('Fehler beim Hinzufügen eines neuen Pools:', error);
        });
    },
    deleteItem(item) {
      this.editedIndex = this.pools.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      BackendService.deletePool({
        id: this.editedItem.id,
      })
        .then((data) => {
          this.pools = data.data.pools;
          this.pool_content = data.data.pool_content;
          this.dialogDelete = false;
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
