<template>
  <v-container>
    <h1 class="text-center">Belohungsanfragen</h1>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="reward_requests"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          class="mr-2"
          @click="acceptRewardRequest(item)"
          v-bind="attrs"
          v-on="on"
        >
          mdi-check
        </v-icon>
          </template>
          <span>Annehmen!</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          class="mr-2"
          @click="cancelRewardRequest(item)"
          v-bind="attrs"
          v-on="on"
        >
          mdi-close
        </v-icon>
          </template>
          <span>Ablehnen!</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.lr_reedem_tstmp`]="{ item }">
        <div v-if="item.lr_reedem_tstmp">
          {{ new Date(item.lr_reedem_tstmp * 1000).toLocaleString('de-De') }}
        </div>
        <i v-else>
          -
        </i>
      </template>
    </v-data-table>
    <v-row>
    <v-col col="6">
    <v-btn
      @click="acceptallRewardRequest()"
      class="success"
    >Alle akzeptieren</v-btn>
    </v-col>
    <v-col col="6" class="text-right">
    <v-btn
      @click="cancelallRewardRequest()"
      class="error ml-auto"
    >Alle ablehnen</v-btn>
    </v-col>
    </v-row>
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'LoyaltyRewardList',
  data: () => ({
    reward_requests: [],
    search: null,
    intervalId: null,
    headers: [
      { text: 'Belohnung', value: 'lr_title', align: 'center' },
      { text: 'User', value: 'username', align: 'center' },
      { text: 'Userinput', value: 'lr_userinput', align: 'center' },
      { text: 'Kosten', value: 'lr_cost', align: 'center' },
      { text: 'Angefragt am', value: 'lr_reedem_tstmp', align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
  }),
  mounted() {
    this.intervalId = window.setInterval(this.updateTimer, 10000);
    BackendService.loadRewardRequests().then((data) => {
      // console.log(data.data);
      this.reward_requests = data.data;
    });
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateTimer() {
      BackendService.loadRewardRequests().then((data) => {
        // console.log(data.data);
        this.reward_requests = data.data;
      });
    },
    acceptRewardRequest(item) {
      console.log(item);
      BackendService.acceptRewardRequests(item).then((data) => {
        // console.log(data.data);
        this.reward_requests = data.data;
      });
    },
    cancelRewardRequest(item) {
      console.log(item);
      BackendService.cancelRewardRequests(item).then((data) => {
        // console.log(data.data);
        this.reward_requests = data.data;
      });
    },
    acceptallRewardRequest() {
      console.log(this.$store.state);
      BackendService.acceptallRewardRequests().then((data) => {
        // console.log(data.data);
        this.reward_requests = data.data;
      });
    },
    cancelallRewardRequest() {
      BackendService.cancelallRewardRequests().then((data) => {
        // console.log(data.data);
        this.reward_requests = data.data;
      });
    },
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}
</style>
