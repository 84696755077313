<template>
  <v-container>
    <h1 class="text-center">Neuer Bot</h1>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Twitchusername"
          v-model="textValue1">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="TwitchRefreshtoken"
          v-model="textValue2">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SpotifyCID"
          v-model="textValue3">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SpotifyCS"
          v-model="textValue4">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SpotifyRF"
          v-model="textValue5">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SpotifyDID"
          v-model="textValue6">
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" @click="saveValues">Erstellen</v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="ProfilSettingsSaved"
      timeout="3000"
      color="green"
    >
      Neuer Bot erstellt!
    </v-snackbar>
  </v-container>
</template>

<style>
.value-label {
  margin-bottom: 8px;
}

.saved-values-card {
  width: 80vw;
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  padding: 16px;
}

.saved-value-label {
  margin-bottom: 8px;
}
</style>

<script>

import BackendService from '@/services/BackendService';

export default {
  data() {
    return {
      ProfilSettingsSaved: false,
      settings: [],
      textValue1: '',
      textValue2: '',
      textValue3: '',
      textValue4: '',
      textValue5: '',
      textValue6: '',
      textValue7: '',
      Twitchusername: 'Twitch Username',
      TwitchRefreshtoken: 'Twitch Refreshtoken',
      SpotifyCID: 'Spotify Client ID',
      SpotifyCS: 'Spotify Client Secret',
      SpotifyDID: 'Spotify Device ID',
      SpotifyRF: 'Spotify Refreshtoken',
      Land: 'Land',
      showSavedValues: true,
      savedValues: {},
      tableHeaders: [
        { text: 'Eigenschaft', value: 'property' },
        { text: 'Wert', value: 'value' },
      ],
    };
  },
  methods: {
    saveValues() {
      BackendService.createBot({
        twitchusername: this.textValue1,
        twitchrefreshtoken: this.textValue2,
        spotifycid: this.textValue3,
        spotifycs: this.textValue4,
        spotifyrf: this.textValue5,
        spotifydid: this.textValue6,
      }).then(() => {
        this.ProfilSettingsSaved = true;
      }).catch(() => {
        alert('nö!');
      });
    },
  },
};
</script>
