<template>
  <v-container>
    <h1 class="text-center">Loyality Rewards</h1>
    <div>
      <p>User: {{$store.state.username}}</p>
      <p>{{settings.name}}: {{$store.state.loyaltypoints}}</p>
      <v-btn
        tile
        color="#9147ff"
        class="text--white"
        :href="loginUrl"
        v-if="!$store.state.logged_in"
      >
        <v-icon left>
          mdi-twitch
        </v-icon>
        Login mit Twitch
      </v-btn>
      <v-app>
        <v-container>
          <v-row>
            <v-col v-for="(reward, index) in rewards" :key="index" cols="12" sm="6" md="4">
              <v-card class="card" elevation="2">
                <v-card-title> {{ reward.title }}</v-card-title>
                <v-card-subtitle>Reward Kosten: {{ reward.cost }}</v-card-subtitle>
                <v-card-text>{{ reward.prompt }}</v-card-text>
                <v-card-text v-if="reward.max_per_stream_is_enabled !== 0
                || reward.max_per_user_per_stream_is_enabled !== 0">
                  <span v-if="reward.max_per_stream_is_enabled !== 0">
                  Einlösungen: {{ `${reward.redeemed_stream} / ${reward.max_per_stream || 0}` }}
                  </span>
                  <br>
                  <span v-if="reward.max_per_user_per_stream_is_enabled !== 0">
                    Benutzer Einlösungen: {{
                      `${reward.redeemed_user} / ${reward.max_per_user_per_stream || 0}`
                    }}
                  </span>
                </v-card-text>
                <v-card-actions class="card-actions">
                  <v-btn
                    color="primary"
                    @click="redeemReward(index)"
                    :disabled="reward.is_paused === 1 || reward.redeemed_user >=
                     reward.max_per_user_per_stream
                     && reward.max_per_user_per_stream_is_enabled === 1
                      || reward.redeemed_stream >= reward.max_per_stream
                      && reward.max_per_stream_is_enabled === 1
                      || waiting === 1"
                  >Einlösen</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-app>
      <v-dialog v-model="showInputDialog" max-width="400">
        <v-card>
          <v-card-title>Eingabe erforderlich</v-card-title>
          <v-card-text>
            <v-text-field v-model="userInput" label="Gib deine Eingabe ein"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="cancelInput">Abbrechen</v-btn>
            <v-btn @click="confirmInput()">Bestätigen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="errorReedemReward"
      timeout="5000"
      color="red"
    >
      {{ dynamicErrorMsg }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="errorReedemReward = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="ReedemReward"
      timeout="3000"
      color="green"
    >
      {{ dynamicReedemMsg }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="ReedemReward = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'LoayltiyRewardsView',

  data: () => ({
    sendData: {},
    settings: [],
    intervalId: null,
    rewards_raw: [],
    userInput: '',
    showInputDialog: false,
    currentIndex: null,
    selectReward: null,
    errorReedemReward: false,
    dynamicErrorMsg: '',
    ReedemReward: false,
    dynamicReedemMsg: '',
    currentReedemRewards: [],
    redeemCounts: {},
    waiting: 0,
    socket: null,
  }),
  mounted() {
    this.sendData = {};
    this.sendData.id = this.$router.currentRoute.params.id;
    // console.log(this.$store.state.username);
    // this.intervalId = window.setInterval(this.updateTimer, 4000);

    BackendService.loadRewardsPublic(this.sendData);
    // console.log(this.$wsurl);
    this.socket = new WebSocket(this.$wsurl);
    this.socket.addEventListener('message', (event) => {
      const command = JSON.parse(event.data);
      let newData = null;
      if (command) {
        switch (command.cmd) {
          case 'init':
            this.socket.send(`{"cmd":"init", "id":"${this.$router.currentRoute.params.id}"}`);
            break;
          case 'initdata':
            this.settings = command.data.settings;
            this.rewards_raw = command.data.rewards;
            // console.log(command.data);
            break;
          case 'update':
            newData = this.rewards_raw.filter((item) => item.cp_id !== command.data.cp_id);
            newData.push(command.data);
            this.rewards_raw = newData;
            // console.log(this.rewards_raw);
            break;
          case 'add':
            this.rewards_raw.push(command.data);
            break;
          case 'delete':
            this.rewards_raw = this.rewards_raw.filter((item) => item.cp_id !== command.data);
            break;
          case 'redeem':
            newData = this.rewards_raw.filter((item) => item.cp_id !== command.data.cp_id);
            newData.push(command.data);
            this.rewards_raw = newData;
            // console.log(this.rewards_raw);
            break;
          default:
            console.log('unknown cmd: ', event.data);
            break;
        }
      }
    });
    // console.log(this.socket);
  },
  computed: {
    rewards() {
      return this.rewards_raw
        .filter((reward) => reward.is_enabled === 1)
        .sort((a, b) => a.cost - b.cost);
    },
    loginUrl() {
      this.$store.commit('set_login_from', 'rewards');
      return `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_TWITCH_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_TWITCH_REDIRECT_URL}&scope=user%3Aread%3Aemail&state=${this.$router.currentRoute.params.id}`;
    },
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateTimer() {
      BackendService.loadRewardsPublic(this.sendData).then((data) => {
        // console.log(data.data);
        this.rewards = data.data.rewards
          .filter((reward) => reward.is_enabled === 1)
          .sort((a, b) => a.cost - b.cost);
        this.currentReedemRewards = data.data.reedem_reward;
        this.settings = data.data.settings;
      });
    },
    redeemReward(index) {
      const reward = this.rewards[index];
      if (reward.is_user_input_required === 1) {
        // Öffne den Dialog für die Benutzereingabe
        this.showInputDialog = true;
        this.selectReward = this.rewards[index];
        // console.log(this.rewards[index]);
      } else {
        // Führe die normale Einlöselogik durch
        this.performRedeem(reward);
      }
    },
    cancelInput() {
      // Schließe den Dialog und setze das Eingabefeld zurück
      this.showInputDialog = false;
      this.userInput = '';
    },
    confirmInput() {
      // Führe die Einlöselogik mit der Benutzereingabe durch
      const userInput = this.userInput.trim();
      if (userInput !== '') {
        // Hier könntest du die Benutzereingabe verwenden, z.B. mit einer API-Anfrage
        // console.log('Benutzereingabe:', userInput);

        // Führe die normale Einlöselogik durch
        this.performRedeem(this.selectReward, userInput);

        // Schließe den Dialog und setze das Eingabefeld zurück
        this.showInputDialog = false;
        this.userInput = '';
      } else {
        // Benutzereingabe ist erforderlich, aber leer
        // Hier könntest du eine Benachrichtigung für den Benutzer hinzufügen
        // console.error('Benutzereingabe ist erforderlich, aber leer.');
      }
    },
    performRedeem(reward, userinput) {
      // Implementiere deine normale Einlöselogik hier
      this.waiting = 1;
      this.sendData = {};
      this.sendData.reward = reward;
      this.sendData.userinput = userinput;
      this.sendData.userpoints = this.$store.state.loyaltypoints;
      // console.log(reward);
      // console.log(userinput);
      // console.log('Belohnung einlösen:', reward);
      BackendService.redeemRewardsPublic(this.sendData).then((data) => {
        // console.log(data.data);
        this.$store.commit('set_loyaltypoints', data.data.points);
        this.rewards = data.data.rewards
          .sort((a, b) => a.cost - b.cost);
        this.dynamicReedemMsg = 'Reward erfolgreich eingelöst!';
        this.ReedemReward = true;
        this.waiting = 0;
      }).catch((error) => {
        // console.log(error.response.status);
        // console.log(error.response.data);
        if (error.response.status === 409 && error.response.data === 'not_enough_points') {
          this.dynamicErrorMsg = 'Nicht genügen Points für den Reward!';
          this.errorReedemReward = true;
        } else if (error.response.status === 409 && error.response.data === 'global_cooldown_activ') {
          this.dynamicErrorMsg = 'Dieser Reward ist noch im Cooldown!';
          this.errorReedemReward = true;
        } else if (error.response.status === 409 && error.response.data === 'not_enough_slots') {
          this.dynamicErrorMsg = 'In diesem Stream steht dieser Reward leider nicht mehr zur Verfügung!';
          this.errorReedemReward = true;
        } else if (error.response.status === 409 && error.response.data === 'not_enough_slots_for_user') {
          this.dynamicErrorMsg = 'Du hast bereits die maximalen Einlösungen für diesen Reward in diesem Stream erreicht!';
          this.errorReedemReward = true;
        }
        this.waiting = 0;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.giveaway {
  &_header {
    background-color: darkblue;
    color: white;
    text-align: center;
    width: 50vw;
    margin: 10px auto 0 auto;
  }

  &_body {
    text-align: center;
    width: 50vw;
    margin: 10px auto 0 auto;
  }
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-actions {
  margin-top: auto; /* Setzt die Margin für den oberen Bereich auf "auto" */
}

</style>
