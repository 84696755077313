<template>
  <v-container fill-height class="wider">
    <h1 class="text-center">EditOverlay</h1>
    <v-row no-gutters class="fill-most">
      <v-col cols="3" style="background-color: red">x</v-col>
      <v-col cols="9">
        <OverlayComponent
          v-if="overlay.id"
          :width="overlay.width"
          :height="overlay.height"
          :edit="true" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';
import OverlayComponent from '@/components/OverlayComponent.vue';

export default {
  name: 'OverlayEditComponent',
  components: { OverlayComponent },
  data: () => ({
    overlay: {},
  }),
  mounted() {
    BackendService.loadOverlay(this.$router.currentRoute.params.id).then((data) => {
      this.overlay = data.data;
    });
  },
  methods: {
  },
};
</script>
<style>
.fill-most {
  height: calc(100% - 50px)
}

.wider {
  max-width: 99vw !important;
}
</style>
