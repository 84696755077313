<template>
  <div>
    <div class="d-flex justify-center align-center">
    <v-btn class="v-size--large" color="success" @click="openDialog({
      max_per_stream_is_enabled: 0,
      max_per_user_per_stream_is_enabled: 0,
      should_redemptions_skip_request_queue: 0,
      is_user_input_required: 0,
      is_enabled: 0,
      is_vip: 0,
    })">Reward erstellen</v-btn>
  </div>
    <div class="channelPointsWrapper"
         :class="{ drawerOpen : !$store.state.navigation_drawer_mini }">
    <v-row>
      <v-col
        v-for="item in sortedRewards"
        :key="item.id"
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card outlined >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-h6 mb-4">{{ item.title }}</div>
              <v-list-item-title class="text-overline mb-2">{{ item.prompt }}</v-list-item-title>
              <v-list-item-subtitle>Reward Kosten: {{ item.cost }}</v-list-item-subtitle>
              <v-btn :color="item.is_bot_owner === 0 ? 'gray' : 'primary'"
                     dark @click="openDialog(item)">
                Belohnung bearbeiten
              </v-btn>
            </v-list-item-content>
            <v-list-item-avatar tile size="80" color="grey">
              <img :src="item.img" alt="Avatar">
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    </div>

    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="error"
              @click="deleteReward"
              :hidden="selectedReward.id === undefined"
            >
              Delete
            </v-btn>
            <v-btn
              color="success"
              @click="saveChanges"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.title"
                label="Name des Rewards"
                outlined
                counter="45"
                maxLength="45"
                required
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.cost"
                label="Kosten"
                outlined
                type="Number"
                required
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-textarea
                v-model="selectedReward.prompt"
                label="Beschreibung"
                rows="1"
                auto-grow
                outlined
                counter="200"
                maxLength="200"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-textarea>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content v-if="selectedReward.id">
              <v-switch
                v-model="selectedReward.is_paused"
                label="Belohnung in Pause versetzen"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.should_redemptions_skip_request_queue"
                label="Belohnungsanfrage überspringen"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.is_user_input_required"
                label="Ist eine Usereingabe erforderlich?"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.is_enabled"
                label="Belohung ist sichtbar in den Loyality Reward Überischt"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.max_per_stream_is_enabled"
                label="Maximale Einlösungen pro Stream aktivieren"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.max_per_stream"
                label="Maximale Einlösungen pro Stream"
                outlined
                type="Number"
                :disabled="selectedReward.is_bot_owner === 0 ||
                selectedReward.max_per_stream_is_enabled === 0"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.max_per_user_per_stream_is_enabled"
                label="Maximale Einlösungen pro Stream pro User aktivieren"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.max_per_user_per_stream"
                label="Maximale Einlösungen pro Stream pro User"
                outlined
                type="Number"
                :disabled="selectedReward.is_bot_owner === 0 ||
                selectedReward.max_per_user_per_stream_is_enabled === 0"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.is_vip"
                label="WIP"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
            <v-switch
              v-model="selectedReward.chat_msg"
              label="Ausgabe bei Einlösung"
              color="success"
              :true-value="1"
              :false-value="0"
              hide-details
              @change="saveSwitchState(selectedReward)"
              :disabled="selectedReward.is_bot_owner === 0"
            ></v-switch>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <h3 class="text-center">Discord Benachrichtigungseinstellungen!</h3>
        <v-list-item>
          <v-list-item-content>
            <v-switch
              v-model="selectedReward.dc_priv_msg"
              label="User benachrichtigen"
              color="success"
              :true-value="1"
              :false-value="0"
              hide-details
              @change="saveSwitchState(selectedReward)"
              :disabled="selectedReward.is_bot_owner === 0"
            ></v-switch>
          </v-list-item-content>
          <v-list-item-content>
            <v-text-field
              v-model="selectedReward.dc_twitchname"
              label="Twitchuser der per Discord benachrichtigt werden soll"
              outlined
              :disabled="selectedReward.is_bot_owner === 0 ||
                selectedReward.dc_priv_msg === 0"
            ></v-text-field>
          </v-list-item-content>
          </v-list-item>
        <v-list-item>
        <v-list-item-content>
        <v-text-field
          v-model="selectedReward.dc_priv_msgcontent"
          label="Discordnachricht die der angegebene User erhalten soll."
          outlined
          :disabled="selectedReward.is_bot_owner === 0 ||
                selectedReward.dc_priv_msg === 0"
        ></v-text-field>
        </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showNotification"
      max-width="600"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showNotification = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Fehler!!!</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12" style="color: red;">Leider kann diese Kanalpunktebelohnung
           nicht editiert werden! Der Grund dafür ist das diese
           Belohnung nicht vom Dashboard aus erstellt wurde!</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Bist du dir sicher das diese
          Kanalpunktebelohnung gelöscht werden soll?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="errorReedemReward"
      timeout="5000"
      color="red"
    >
      {{ dynamicErrorMsg }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="errorReedemReward = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import BackendService from '@/services/BackendService';

export default {
  data() {
    return {
      dialogDelete: false,
      showDialog: false,
      selectedIndex: 0,
      reward: [],
      errorReedemReward: false,
      dynamicErrorMsg: '',
      selectedReward: {
        id: '',
        title: '',
        prompt: '',
        is_bot_owner: '',
        cost: '',
        is_paused: '',
        should_redemptions_skip_request_queue: '',
        is_user_input_required: '',
        max_per_stream_is_enabled: '',
        max_per_stream: '',
        max_per_user_per_stream_is_enabled: '',
        max_per_user_per_stream: '',
        is_enabled: '',
        chat_msg: '',
        dc_priv_msg: '',
        dc_twitchname: '',
        dc_priv_msgcontent: '',
      },
      showNotification: false,
    };
  },
  mounted() {
    BackendService.loadLoyaltiyRewards().then((data) => {
      this.reward = data.data;
      console.log(this.reward);
    });
  },
  computed: {
    sortedRewards() {
      const sortedArray = this.reward.slice();
      sortedArray.sort((a, b) => {
        if (a.cost !== b.cost) {
          return a.cost - b.cost;
        }
        return a.title.localeCompare(b.title);
      });

      return sortedArray;
    },
  },
  methods: {
    openDialog(item) {
      if (item.is_bot_owner === 0) {
        this.showNotification = true;
        return;
      }

      this.selectedReward = { ...item };
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    saveChanges() {
      if (this.selectedReward.id) {
        // Item bearbeiten
        console.log(this.selectedReward);
        BackendService.updateLoyaltiyReward(this.selectedReward)
          .then((data) => {
            // Aktualisiere den Store mit der Mutationsmethode
            this.reward = data.data.rewards;
            this.showDialog = false;
          }).catch((error) => {
          // console.log(error.response.status);
          // console.log(error.response.data);
            if (error.response.status === 409 && error.response.data === 'user_not_connected_with_streamer_dc') {
              this.dynamicErrorMsg = 'Der angegebene User ist nicht mit dem Streamer DC verbunden!';
              this.errorReedemReward = true;
            } else if (error.response.status === 409 && error.response.data === 'streamer_not_connected_with_dc_bot') {
              this.dynamicErrorMsg = 'Streamer benutzt aktuell den DC-Bot nicht!';
              this.errorReedemReward = true;
            } else if (error.response.status === 409 && error.response.data === 'twitchuser_not_found') {
              this.dynamicErrorMsg = 'Der angegebene Twitchuser wurde nicht gefunden!';
              this.errorReedemReward = true;
            }
          });
      } else {
        // Neues Item hinzufügen
        BackendService.createLoyaltiyReward(this.selectedReward)
          .then((data) => {
            this.reward = data.data.rewards;
            this.showDialog = false;
          }).catch((error) => {
          // console.log(error.response.status);
          // console.log(error.response.data);
            if (error.response.status === 409 && error.response.data === 'user_not_connected_with_streamer_dc') {
              this.dynamicErrorMsg = 'Der angegebene User ist nicht mit dem Streamer DC verbunden!';
              this.errorReedemReward = true;
            } else if (error.response.status === 409 && error.response.data === 'streamer_not_connected_with_dc_bot') {
              this.dynamicErrorMsg = 'Streamer benutzt aktuell den DC-Bot nicht!';
              this.errorReedemReward = true;
            } else if (error.response.status === 409 && error.response.data === 'twitchuser_not_found') {
              this.dynamicErrorMsg = 'Der angegebene Twitchuser wurde nicht gefunden!';
              this.errorReedemReward = true;
            }
          });
      }
    },
    saveSwitchState(item) {
      if (item.is_paused) {
        this.selectedReward.is_paused = 1;
      } else {
        this.selectedReward.is_paused = 0;
      }
      if (item.is_vip) {
        this.selectedReward.is_vip = 1;
      } else {
        this.selectedReward.is_vip = 0;
      }
      if (item.is_enabled) {
        this.selectedReward.is_enabled = 1;
      } else {
        this.selectedReward.is_enabled = 0;
      }
      if (item.is_user_input_required) {
        this.selectedReward.is_user_input_required = 1;
      } else {
        this.selectedReward.is_user_input_required = 0;
      }
      if (item.max_per_user_per_stream_is_enabled) {
        this.selectedReward.max_per_user_per_stream_is_enabled = 1;
      } else {
        this.selectedReward.max_per_user_per_stream_is_enabled = 0;
      }
      if (item.max_per_stream_is_enabled) {
        this.selectedReward.max_per_stream_is_enabled = 1;
      } else {
        this.selectedReward.max_per_stream_is_enabled = 0;
      }
      if (item.should_redemptions_skip_request_queue) {
        this.selectedReward.should_redemptions_skip_request_queue = 1;
      } else {
        this.selectedReward.should_redemptions_skip_request_queue = 0;
      }
      if (item.chat_msg) {
        this.selectedReward.chat_msg = 1;
      } else {
        this.selectedReward.chat_msg = 0;
      }
    },
    deleteReward() {
      // console.log(this.selectedReward.id);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // console.log(this.selectedReward.id);
      // console.log('LÖSCHEN!!!');
      BackendService.deleteLoyaltiyReward(this.selectedReward).then((data) => {
        this.dialogDelete = false;
        this.showDialog = false;
        this.reward = data.data.rewards;
      });
    },
    closeDelete() {
      // console.log(this.selectedReward.id);
      // console.log('NICHT LÖSCHEN!!!');
      this.dialogDelete = false;
    },
  },
};
</script>
<style>
.channelPointsWrapper {
  position: relative;
  left: 20px;
  top: 10px;
  bottom: 0;
  padding-bottom: 30px;
  width: calc(100% - 100px);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: width;

  &.drawerOpen {
    width: calc(100% - 300px);
  }
}

</style>
