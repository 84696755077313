<template>
  <v-container>
    <h1 class="text-center">Profil</h1>
    <h2 class="text-center error--text">Achtung! Sofern das Tool geschäftlich genutzt wird trage
      bitte deine Umsatzsteuer-Identifikationsnummer (USt-IdNr.) mit ein.</h2>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Vorname"
          v-model="textValue1">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Nachname"
          v-model="textValue2">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          :label="Strasse"
          v-model="textValue3">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :label="Hausnr"
          type="Number"
          v-model="textValue4">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="PLZ"
          type="Number"
          v-model="textValue5">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Stadt"
          v-model="textValue6">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Land"
          v-model="textValue7">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="UStIdNr"
          v-model="textValue8">
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" @click="saveValues">Speichern</v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="ProfilSettingsSaved"
      timeout="3000"
      color="green"
    >
      Profil Settings gespeichert!
    </v-snackbar>
  </v-container>
</template>

<style>
.value-label {
  margin-bottom: 8px;
}

.saved-values-card {
  width: 80vw;
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  padding: 16px;
}

.saved-value-label {
  margin-bottom: 8px;
}
</style>

<script>

import BackendService from '@/services/BackendService';

export default {
  data() {
    return {
      ProfilSettingsSaved: false,
      settings: [],
      textValue1: '',
      textValue2: '',
      textValue3: '',
      textValue4: '',
      textValue5: '',
      textValue6: '',
      textValue7: '',
      textValue8: '',
      Vorname: 'Vorname',
      Nachname: 'Nachname',
      Strasse: 'Straße',
      Hausnr: 'Hausnummer',
      PLZ: 'PLZ',
      Stadt: 'Stadt',
      Land: 'Land',
      UStIdNr: 'USt-IdNr.',
      showSavedValues: true,
      savedValues: {},
      tableHeaders: [
        { text: 'Eigenschaft', value: 'property' },
        { text: 'Wert', value: 'value' },
      ],
    };
  },
  mounted() {
    BackendService.loadProfilSettings().then((data) => {
      console.log(data.data);
      this.textValue1 = data.data.vorname;
      this.textValue2 = data.data.name;
      this.textValue3 = data.data.strasse;
      this.textValue4 = data.data.hausnr;
      this.textValue5 = data.data.plz;
      this.textValue6 = data.data.stadt;
      this.textValue7 = data.data.land;
      this.textValue8 = data.data.ustidnr;
    });
  },
  methods: {
    saveValues() {
      BackendService.saveProfilSettings({
        vorname: this.textValue1,
        name: this.textValue2,
        strasse: this.textValue3,
        hausnr: this.textValue4,
        plz: this.textValue5,
        stadt: this.textValue6,
        land: this.textValue7,
        ustidnr: this.textValue8,
      }).then(() => {
        this.ProfilSettingsSaved = true;
      }).catch(() => {
        alert('nö!');
      });
    },
  },
};
</script>
