<template>
  <div>
    <div v-if="loaded" >
      <h3>
        <span class="prefix_dono"> Monthly Top Donator: </span>
        <span class="dono"> {{this.top_dono}} </span>
      </h3>
    </div>
    <div v-else>
      Loading....
    </div>

  </div>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'TopDonoView',

  data: () => ({
    top_dono: 0,
    loaded: false,
  }),
  components: {
  },
  mounted() {
    this.intervalId = window.setInterval(this.updateTimer, 2500);
    this.updateTimer();
  },
  computed: {
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateTimer() {
      BackendService.loadTopDono(this.$router.currentRoute.params.token).then((data) => {
        // console.log(data.data);
        this.loaded = true;
        this.top_dono = data.data.top_dono;
      });
    },
  },
};

</script>

<style lang="scss">
.transparent.theme--dark.v-application {
  background-color: transparent;
  color: inherit;
}

html {
  overflow: auto;
}
</style>
