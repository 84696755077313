<template>
  <v-card
    class="saved-values-card ma-auto"
    max-width="600">
    <v-card-text>
      <v-list two-line>
        <v-list-item
          v-for="key in ['amount', 'sub_multiplier_t1', 'sub_multiplier_t2',
          'sub_multiplier_t3', 'follow_bonus','tip_bonus',
           'sub_bonus_t1', 'sub_bonus_t2', 'sub_bonus_t3', 'cheer_bonus']" :key="key">
          <v-list-item-icon>
            <v-icon color="indigo">
              {{ loyaltyCardData[key].icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ loyaltyCardData[key].title }}</v-list-item-title>
            <v-list-item-subtitle v-if="['sub_multiplier_t1', 'sub_multiplier_t2',
             'sub_multiplier_t3'].includes(key)">
              {{ loyaltyCardData[key].text(
              settings[key] * settings.amount, settings.name
            ) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              {{ loyaltyCardData[key].text(settings[key], settings.name) }}
            </v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>

      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LoyaltyCard',
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loyaltyCardData: {
        amount: {
          icon: 'mdi-account-multiple',
          title: 'Zuschauen',
          text: (value, name) => `Alle 10 Minuten werden ${value} ${name} gutgeschrieben`,
        },
        sub_multiplier_t1: {
          icon: 'mdi-account-multiple-plus',
          title: 'Abonnenten Stufe 1',
          text: (value, name) => `Alle 10 Minuten werden ${Math.floor(value)} ${name} gutgeschrieben`,
        },
        sub_multiplier_t2: {
          icon: 'mdi-account-multiple-plus',
          title: 'Abonnenten Stufe 2',
          text: (value, name) => `Alle 10 Minuten werden ${Math.floor(value)} ${name} gutgeschrieben`,
        },
        sub_multiplier_t3: {
          icon: 'mdi-account-multiple-plus',
          title: 'Abonnenten Stufe 3',
          text: (value, name) => `Alle 10 Minuten werden ${Math.floor(value)} ${name} gutgeschrieben`,
        },
        follow_bonus: {
          icon: 'mdi-heart',
          title: 'Folgen',
          text: (value, name) => `Für das Folgen werden ${value} ${name} gutgeschrieben`,
        },
        tip_bonus: {
          icon: 'mdi-currency-usd',
          title: 'Spenden',
          text: (value, name) => `Alle nicht anonymen Spenden bekommen für jeden 1€ ${value} ${name}`,
        },
        sub_bonus_t1: {
          icon: 'mdi-star-circle',
          title: 'Abonnieren Stufe 1',
          text: (value, name) => `Für das abonieren auf Stufe 1 werden ${value} ${name} gutgeschrieben`,
        },
        sub_bonus_t2: {
          icon: 'mdi-star-circle',
          title: 'Abonnieren Stufe 2',
          text: (value, name) => `Für das abonieren auf Stufe 2 werden ${value} ${name} gutgeschrieben`,
        },
        sub_bonus_t3: {
          icon: 'mdi-star-circle',
          title: 'Abonnieren Stufe 3',
          text: (value, name) => `Für das abonieren auf Stufe 3 werden ${value} ${name} gutgeschrieben`,
        },
        cheer_bonus: {
          icon: 'mdi-diamond-stone',
          title: 'Bits spenden',
          text: (value, name) => `Für jede 100 Bits Spende gibt es ${value} ${name} `,
        },
      },
    };
  },
};
</script>

<style scoped>
.v-list-item__subtitle {
  text-overflow: initial !important;
  white-space: initial !important;
  overflow: auto !important;
}
</style>
