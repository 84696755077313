<template>
  <v-container>
    <h1 class="text-center">Overlays</h1>
    <v-btn
      class="mx-2"
      fab
      dark
      color="primary"
      @click="newOverlay"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>

    <v-row>
      <v-col v-for="overlay in overlays" :key="overlay.id" cols="2">
        <v-card
          class="mx-auto my-12"
          max-width="374"
        >
          <v-img
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
          ></v-img>

          <v-card-title>{{ overlay.name }}</v-card-title>

          <v-card-actions>
            <v-btn
              color="primary"
              @click="edit(overlay)"
            >
              bearbeiten
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'OverlayViewComponent',
  data: () => ({
    overlays: [],
  }),
  mounted() {
    BackendService.loadOverlays().then((data) => {
      this.overlays = data.data;
    });
  },
  methods: {
    edit(overlay) {
      const routeData = this.$router.resolve({ name: 'editoverlay', params: { id: overlay.id } });
      window.open(routeData.href, '_blank');
    },
    newOverlay() {
      BackendService.createOverlay().then((data) => {
        this.edit({ id: data.data.insertId });
      });
    },
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}
</style>
