<template>
  <v-container>
    <h1 class="text-center mb-8" >OBS Integration </h1>
    <v-row class="justify-center">
      <div>
        <p class="text-center"> Hier gibt es aktuell die Möglichkeit die Sub/Bit Goals
        als Integration für OBS zu implementieren.</p>
        <p class="text-center"> Einfach den Link in OBS als Browserquelle
        importieren und es kann los gehen!</p>
        <p class="text-center"> Die Goals können unter "Settings" eingestellt werden.</p>
        <h3 class="text-center red--text text-decoration-underline mb-5">
          WICHTIG!!! Teile diese Links mit niemanden!</h3>
        <div class="custom-container3">
          <v-row>
          <v-col cols="12" md="9" class="mt-2">
        <p class="text-center"> <span style="color: #00ffcd;">Sub Goal Link:</span>
          {{baseUrl}}/obs/daily_sub_goal/{{obstoken}}</p>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn color="primary" @click="editPrefix('daily_subgoal')">Prefix editieren</v-btn>
          </v-col>
            <v-col cols="12" md="9" class="mt-2">
          <p class="text-center"> <span style="color: #00ffcd;"> Daily Subs Link: </span>
            {{baseUrl}}/obs/daily_subs/{{obstoken}}</p>
            </v-col>
            <v-col cols="12" md="9" class="mt-2">
          <p class="text-center"> <span style="color: #00ffcd;"> Bit Goal Link: </span>
            {{baseUrl}}/obs/daily_bit_goal/{{obstoken}}</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn color="primary" @click="editPrefix('daily_bitgoal')">Prefix editieren</v-btn>
            </v-col>
            <v-col cols="12" md="9" class="mt-2">
          <p class="text-center"> <span style="color: #00ffcd;"> Daily Bits Link: </span>
            {{baseUrl}}/obs/daily_bits/{{obstoken}}</p>
            </v-col>
          </v-row>
        </div>
        <h3 class="pb-3">Einstellungen:</h3>
        <p>• Browserquelle hinzufügen</p>
        <p>• gewünschte URL einfügen</p>
        <v-img
          src="https://i.imgur.com/6ZKnNgd.png"
        ></v-img>
        <p class="pt-5">• Optional kann ein CSS Code hinzugefügt werden</p>
        <p class="ml-5">• beinflusst u.a. Schriftgröße/art</p>
        <p class="ml-5">• Gelber Hintergrund dient nur zur Präsentation und
          ist im CSS Code nicht enthalten</p>
        <div class="custom-container2 pb-5" >
          <p>Beispiel 1:</p>
          <v-row align="center" justify="center">
            <v-img
              class="mb-7 text-center"
              max-width="450"
              src="https://i.imgur.com/i9m68Yp.png"
            ></v-img>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              color="green"
              outlined
              @click="copyToClipboard(1)"
            >
              CSS kopieren
            </v-btn>
          </v-row>
        </div>
        <div class="custom-container2 pb-5" >
          <p>Beispiel 2:</p>
          <v-row align="center" justify="center">
            <v-img
              class="mb-7 text-center"
              max-width="450"
              src="https://i.imgur.com/NE1bC87.png"
            ></v-img>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              color="green"
              outlined
              @click="copyToClipboard(2)"
            >
              CSS kopieren
            </v-btn>
          </v-row>
        </div>
        <div class="custom-container2 pb-5" >
          <p>Beispiel 3:</p>
          <v-row align="center" justify="center">
            <v-img
              class="mb-7 text-center"
              max-width="450"
              src="https://i.imgur.com/sqlPNiG.png"
            ></v-img>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              color="green"
              outlined
              @click="copyToClipboard(3)"
            >
              CSS kopieren
            </v-btn>
          </v-row>
        </div>
        <div class="custom-container2 pb-5" >
          <p>Beispiel 4:</p>
          <v-row align="center" justify="center">
            <v-img
              class="mb-7 text-center"
              max-width="450"
              src="https://i.imgur.com/HzcyduV.png"
            ></v-img>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              color="green"
              outlined
              @click="copyToClipboard(4)"
            >
              CSS kopieren
            </v-btn>
          </v-row>
        </div>
          <v-snackbar
            v-model="copySuccess"
            timeout="1500"
            color="green"
          >
            CSS Code erfolreich kopiert!
          </v-snackbar>
      </div>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="50vw"
    >
      <v-card>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.var_content"
                    label="Prefix"
                    :rules="[v => !!v || 'Prefix is required']"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="4"
                >
                  <h2>Als Beispiel: <span class="red--text">Prefix</span> 0/100 </h2>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :loading="saving"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'OBSView',
  data: () => ({
    obstoken: '',
    baseUrl: '',
    copySuccess: false,
    dialog: false,
    prefixe: '',
    saving: false,
    editedItem: {
    },
    editedIndex: -1,
  }),
  components: {
  },
  mounted() {
    this.baseUrl = window.location.origin;
    BackendService.loadObsToken({
      broadcasterid: this.$store.state.hostId,
    }).then((data) => {
      // console.log(data.data);const targetArray = data.data.find((item1) => item1.id === item.id);
      // console.log(targetArray);
      // console.log('Daten für OBS Token!');
      // console.log(data.data);
      this.obstoken = data.data.obs_token;
      this.prefixe = data.data.prefixe;
    });
  },
  methods: {
    copyToClipboard(auswahl) {
      let cssConfig = 0;
      if (auswahl === 1) {
        cssConfig = `
h3 {
font-family: Arial !important;
}s
`;
      } else if (auswahl === 2) {
        cssConfig = `body {
color: yellow;
-webkit-text-stroke: 2px black;
text-shadow:
3px 3px 0 #000
;
}
h3 {
font-family: fantasy;
}
`;
      } else if (auswahl === 3) {
        cssConfig = `body {
color: white;
-webkit-text-stroke: 2px black;
}
h3 {
    font-family: "Courier New", monospace;
}
        `;
      } else if (auswahl === 4) {
        cssConfig = `body {
color: black;
-webkit-text-stroke: 2px white;
text-shadow:
    3px 3px 0 white;
}
h3 {
    font-family: "Georgia", serif;
}
        `;
      }

      // Erstelle ein Textarea-Element und füge die CSS-Konfiguration hinzu
      const textarea = document.createElement('textarea');
      textarea.value = cssConfig;

      // Füge das Textarea-Element zum DOM hinzu (außerhalb des Sichtbaren)
      document.body.appendChild(textarea);

      // Selektiere den Text im Textarea
      textarea.select();

      // Kopiere den ausgewählten Text in die Zwischenablage
      document.execCommand('copy');

      // Entferne das Textarea-Element aus dem DOM
      document.body.removeChild(textarea);

      this.copySuccess = true;
    },
    editPrefix(item) {
      console.log(this.prefixe);
      for (let i = 0; i < this.prefixe.length; i += 1) {
        const currentItem = this.prefixe[i];
        if (currentItem.var_name === `prefix_${item}`) {
          console.log(currentItem);
          this.editedItem = currentItem;
        }
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.saving = true;
      // console.log(this.editedItem);
      BackendService.savePrefix({
        item: this.editedItem,
        db: this.$store.state.db,
      }).then((data) => {
        this.prefixe = data.data.prefixe;
      }).catch(() => {
        alert('nö!');
      }).finally(() => {
        this.saving = false;
        this.close();
      });
    },
  },
};
</script>
