<template>
  <v-container>
    <h1 class="text-center">Commands</h1>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="commands"
      sort-by="calories"
      class="elevation-1"
    >
    </v-data-table>

  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'CustomCommands',
  data: () => ({
    commands: [],
    saving: false,
    search: null,
    Rang: ['Everyone', 'Sub', 'Vip', 'Mod', 'Editor', 'Admin', 'Owner'],
    headers: [
      { text: 'Perm', value: 'perm', align: 'center' },
      { text: 'Command', value: 'command', align: 'center' },
      { text: 'Alias', value: 'alias', align: 'center' },
      { text: 'Description', value: 'description', sortable: false },
    ],
  }),
  mounted() {
    BackendService.loadCommandList(this.$router.currentRoute.params.id).then((data) => {
      this.commands = data.data;
    });
  },
  methods: {
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}
</style>
