<template>
  <v-container>
    <h1 class="text-center">Giveaway</h1>

    <div v-if="giveawayLoaded && !giveawayFound">
      Nice try, goodbye!
    </div>
    <div v-else-if="giveawayLoaded && giveawayFound">
      <h2 class="text-center">{{ giveaway.title }}</h2>
      <p class="text-center">{{giveaway.description}}</p>
      <p>User: {{$store.state.username}}</p>
      <p>{{settings.name}}: {{$store.state.loyaltypoints}}</p>
      <v-btn
        tile
        color="#9147ff"
        class="text--white"
        :href="loginUrl"
        v-if="!$store.state.logged_in"
      >
        <v-icon left>
          mdi-twitch
        </v-icon>
        Login mit Twitch
      </v-btn>

      <v-row class="giveaway_header">
        <v-col cols="3">Teilnehmer</v-col>
        <v-col cols="3">Gekaufte Tickets</v-col>
        <v-col cols="3">Abgelaufene Zeit</v-col>
        <v-col cols="3">Kosten pro Ticket</v-col>
      </v-row>
      <v-row class="giveaway_body">
        <v-col cols="3">{{giveaway.unique_entries}}</v-col>
        <v-col cols="3">{{giveaway.total_entries}}</v-col>
        <v-col cols="3">{{ timer }}</v-col>
        <v-col cols="3">{{giveaway.cost_per_ticket}} {{settings.name}}</v-col>
      </v-row>
      <div>
        <v-row class="text-center">
          <v-col cols="12" sm="4" offset-sm="4">
            <v-text-field
              v-model="ticket_buy"
              label="Ticket kaufen"
              type="number"
              min="0"
              :max="Math.min(
                this.giveaway.ticker_per_user - this.$store.state.ticket_from_user,
                Math.floor(this.$store.state.loyaltypoints / this.giveaway.cost_per_ticket)
                )"
              class=""
              :error="ticketBuyError !== null"
              :error-messages="ticketBuyError"
              @focus="ticketBuyError = null"
              :hint="'Du nimmst bereits mit: ' + $store.state.ticket_from_user +
               ' Tickets teil! '"  persistent-hint
            ></v-text-field>
            <v-btn
              tile
              color="#9147ff"
              class="text--white"
              :href="loginUrl"
              v-if="!$store.state.logged_in"
            >
              <v-icon left>
                mdi-twitch
              </v-icon>
              Login mit Twitch
            </v-btn>
            <v-btn
              tile
              color="#9147ff"
              class="text--white"
              @click="ticketbuy()"
              v-else
            >
              Ticket kaufen
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <LoyaltyCard :settings="settings" />
    </div>
    <div v-else>
      Loading....
    </div>

  </v-container>
</template>

<script>

import BackendService from '@/services/BackendService';
import LoyaltyCard from '@/components/LoyaltyCard.vue';

export default {
  name: 'GiveawayView',

  data: () => ({
    sendData: {},
    ticket_buy: '',
    giveawayLoaded: false,
    giveawayFound: false,
    giveaway: {},
    settings: {},
    intervalId: null,
    timer: null,
    ticketBuyError: null,
  }),
  components: {
    LoyaltyCard,
  },
  mounted() {
    this.sendData = {};
    this.sendData.id = this.$router.currentRoute.params.id;
    this.sendData.username = this.$store.state.username;
    // console.log(this.$store.state.username);
    this.intervalId = window.setInterval(this.updateTimer, 1000);
    BackendService.loadSingleGiveaway(this.sendData).then((data) => {
      // console.log(data.data);
      if (data.data.giveaway.length > 0) {
        this.giveawayFound = true;
        this.giveaway = { ...data.data.giveaway[0] };
        this.settings = { ...data.data.settings };
        // console.log(this.giveaway);
        this.$store.commit('set_ticket', data.data.giveaway[0].ticket_from_user);
      }
      this.giveawayLoaded = true;
    });
  },
  computed: {
    loginUrl() {
      this.$store.commit('set_login_from', 'giveaway');
      return `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_TWITCH_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_TWITCH_REDIRECT_URL}&scope=user%3Aread%3Aemail&state=${this.$router.currentRoute.params.id}`;
    },
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    ticketbuy() {
      const ticketBuy = parseInt(this.ticket_buy, 10);
      if (Number.isNaN(ticketBuy)) {
        this.ticketBuyError = 'Bitte gib eine Zahl ein';
      } else if (ticketBuy < 1) {
        this.ticketBuyError = 'Du must schon mindestens ein Ticket kaufen...';
      } else if (this.giveaway.cost_per_ticket * ticketBuy > this.$store.state.loyaltypoints) {
        this.ticketBuyError = `Du kannst dir maximal ${Math.floor(this.$store.state.loyaltypoints / this.giveaway.cost_per_ticket)} Tickets leisten`;
      } else if (this.giveaway.ticker_per_user < ticketBuy + this.$store.state.ticket_from_user) {
        this.ticketBuyError = `Du kannst maximal ${this.giveaway.ticker_per_user} Tickets hierfür haben - da du schon ${this.$store.state.ticket_from_user} hast, kannst dir maximal ${this.giveaway.ticker_per_user - this.$store.state.ticket_from_user} Tickets kaufen`;
      } else {
        BackendService.ticketbuy({ tickets: ticketBuy, giveaway: this.giveaway.id })
          .then((data) => {
            this.ticket_buy = null;
            if (data.data.points !== undefined) {
              this.$store.commit('set_loyaltypoints', data.data.points);
            }
            if (data.data.tickets !== undefined) {
              this.$store.commit('set_ticket', data.data.tickets);
            }
          });
      }
    },
    updateTimer() {
      if (this.giveawayFound) {
        let out = [];
        let seconds = Math.floor(new Date().getTime() / 1000) - this.giveaway.started;
        out.push(Math.floor(seconds / 86400));
        seconds %= 86400;
        out.push(Math.floor(seconds / 3600));
        seconds %= 3600;
        out.push(Math.floor(seconds / 60));
        seconds %= 60;

        while (out.length > 0 && out[0] === 0) {
          out.shift();
        }
        out.push(seconds);

        out = out.map((value, index) => (index === 0 ? value : (`${value}`).padStart(2, '0')));

        this.timer = out.join(':');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.giveaway {
  &_header {
    background-color: darkblue;
    color: white;
    text-align: center;
    width: 50vw;
    margin: 10px auto 0 auto;
  }

  &_body {
    text-align: center;
    width: 50vw;
    margin: 10px auto 0 auto;
  }
}
</style>
