var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',{staticClass:"text-center"},[_vm._v("Loyality Rewards")]),_c('div',[_c('p',[_vm._v("User: "+_vm._s(_vm.$store.state.username))]),_c('p',[_vm._v(_vm._s(_vm.settings.name)+": "+_vm._s(_vm.$store.state.loyaltypoints))]),(!_vm.$store.state.logged_in)?_c('v-btn',{staticClass:"text--white",attrs:{"tile":"","color":"#9147ff","href":_vm.loginUrl}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-twitch ")]),_vm._v(" Login mit Twitch ")],1):_vm._e(),_c('v-app',[_c('v-container',[_c('v-row',_vm._l((_vm.rewards),function(reward,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" "+_vm._s(reward.title))]),_c('v-card-subtitle',[_vm._v("Reward Kosten: "+_vm._s(reward.cost))]),_c('v-card-text',[_vm._v(_vm._s(reward.prompt))]),(reward.max_per_stream_is_enabled !== 0
              || reward.max_per_user_per_stream_is_enabled !== 0)?_c('v-card-text',[(reward.max_per_stream_is_enabled !== 0)?_c('span',[_vm._v(" Einlösungen: "+_vm._s(`${reward.redeemed_stream} / ${reward.max_per_stream || 0}`)+" ")]):_vm._e(),_c('br'),(reward.max_per_user_per_stream_is_enabled !== 0)?_c('span',[_vm._v(" Benutzer Einlösungen: "+_vm._s(`${reward.redeemed_user} / ${reward.max_per_user_per_stream || 0}`)+" ")]):_vm._e()]):_vm._e(),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-btn',{attrs:{"color":"primary","disabled":reward.is_paused === 1 || reward.redeemed_user >=
                   reward.max_per_user_per_stream
                   && reward.max_per_user_per_stream_is_enabled === 1
                    || reward.redeemed_stream >= reward.max_per_stream
                    && reward.max_per_stream_is_enabled === 1
                    || _vm.waiting === 1},on:{"click":function($event){return _vm.redeemReward(index)}}},[_vm._v("Einlösen")])],1)],1)],1)}),1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showInputDialog),callback:function ($$v) {_vm.showInputDialog=$$v},expression:"showInputDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Eingabe erforderlich")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Gib deine Eingabe ein"},model:{value:(_vm.userInput),callback:function ($$v) {_vm.userInput=$$v},expression:"userInput"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.cancelInput}},[_vm._v("Abbrechen")]),_c('v-btn',{on:{"click":function($event){return _vm.confirmInput()}}},[_vm._v("Bestätigen")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":"5000","color":"red"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"pink","variant":"text"},on:{"click":function($event){_vm.errorReedemReward = false}}},[_vm._v(" Close ")])]},proxy:true}]),model:{value:(_vm.errorReedemReward),callback:function ($$v) {_vm.errorReedemReward=$$v},expression:"errorReedemReward"}},[_vm._v(" "+_vm._s(_vm.dynamicErrorMsg)+" ")]),_c('v-snackbar',{attrs:{"timeout":"3000","color":"green"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"pink","variant":"text"},on:{"click":function($event){_vm.ReedemReward = false}}},[_vm._v(" Close ")])]},proxy:true}]),model:{value:(_vm.ReedemReward),callback:function ($$v) {_vm.ReedemReward=$$v},expression:"ReedemReward"}},[_vm._v(" "+_vm._s(_vm.dynamicReedemMsg)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }