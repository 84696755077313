<template>
  <v-container>
    <h1 class="text-center">{{ this.title }}</h1>
    <v-row>
      <v-col cols="6">
    <v-btn
      color="success"
      class="mr-4"
      @click="pickWinner"
    >
      Gewinner ziehen!
    </v-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          color="success"
          class="ml-auto"
          @click="lastWinner"
        >
          Letzter Gewinner!
        </v-btn>
      </v-col>
    </v-row>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="giveaways"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          max-width="80vw"
        >
          <v-card>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.title"
                      label="Titel"
                      :rules="[v => !!v || 'Titel is required']"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                  >
                    <v-checkbox v-model="editedItem.confirm_buyin" label="Kauf bestätigung" />
                  </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Sicher diesen User aus
              dem Giveaway löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog class ="justify-content=center" v-model="dialogWinner" max-width="500px">
          <v-card class="text-center">
            <v-card-title class="text-h5 ">
              Der Gewinner dieses Giveaways ist:<br>{{ winner }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click=
                "pickWinner(safeWinnerItem)">Reroll!</v-btn>
              <v-btn color="blue darken-1" text @click=
                "closeGiveawayWinnerDialog">Verstanden!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog class ="justify-content=center" v-model="dialogWatchWinner" max-width="500px">
          <v-card class="text-center">
            <v-card-title class="text-h5 ">
              Der Gewinner dieses Giveaways war:<br>{{ winner }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click=
                "closeGiveawayWatchWinnerDialog">Verstanden!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.confirm_buyin`]="{ item }">
          <div>
            {{ item.confirm_buyin === 1 ? 'Ja' : 'Nein' }}
          </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          class="mr-2"
          :disabled="item.started ==! null && item.stopped === null"
          @click="deleteItem(item)"
          v-bind="attrs"
          v-on="on"
        >
          mdi-delete
        </v-icon>
          </template>
          <span>Löschen!</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.tstamp`]="{ item }">
        <div v-if="item.tstamp">
          {{ new Date(item.tstamp * 1000).toLocaleString('de-De') }}
        </div>
        <i v-else>
          -
        </i>
      </template>
    </v-data-table>

  </v-container>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'giveawayNoPointsEntrant',

  data: () => ({
    title: '',
    safeWinnerItem: {},
    winner: '',
    search: '',
    dialog: false,
    dialogWinner: false,
    dialogWatchWinner: false,
    saving: false,
    giveaways: [],
    valid: true,
    intervalId: '',
    headers: [
      { text: 'Position', value: 'pos', align: 'center' },
      { text: 'Username', value: 'username', align: 'center' },
      { text: 'Beigetreten', value: 'tstamp', align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
  }),
  components: {
  },
  mounted() {
    BackendService.loadGiveawayNoPointsEntrant({
      id: this.$route.params.id,
    }).then((data1) => {
      this.title = data1.data[0].title;
      this.giveaways = data1.data;
    });
    this.intervalId = setInterval(() => {
      BackendService.loadGiveawayNoPointsEntrant({
        id: this.$route.params.id,
      }).then((data1) => {
        // console.log(data1);
        this.title = data1.data[0].title;
        this.giveaways = data1.data;
      });
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.giveaways.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    copyItem(item) {
      this.editedItem = { ...item };
      delete this.editedItem.id;
      this.dialog = true;
    },
    newGiveaway() {
      this.editedIndex = -1;
      this.editedItem = { };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      // console.log(this.editedItem.id);
      BackendService.deleteGiveawayNoPointsUser({
        id: this.$route.params.id,
        username: this.editedItem.username,
      }).then((data) => {
        this.giveaways = data.data;
        this.closeDelete();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      // console.log(item);
      this.editedIndex = this.giveaways.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    startItem(item) {
      BackendService.startGiveawayNoPoints({
        id: item.id,
        title: item.title,
      }).then((data) => {
        this.giveaways = data.data;
        this.intervalId = setInterval(() => {
          BackendService.loadGiveawaysNoPoints().then((data1) => {
            this.giveaways = data1.data;
          });
        }, 10000);
      });
    },
    stopItem(item) {
      BackendService.stopGiveawayNoPoints({
        id: item.id,
        title: item.title,
      }).then((data) => {
        clearInterval(this.intervalId);
        this.giveaways = data.data;
      });
    },
    pickWinner() {
      if (this.dialogWinner === true) this.dialogWinner = false;
      BackendService.pickWinnerGiveawayNoPoints({
        id: this.$route.params.id,
      }).then((data) => {
        clearInterval(this.intervalId); // stoppt auto refresh
        // console.log(this.$route.params.id);
        // console.log(data.data);
        this.giveaways = data.data.users;
        this.winner = data.data.winner;
        this.safeWinnerItem = data.data.winner;
        this.dialogWinner = true;
      });
    },
    lastWinner() {
      if (this.dialogWatchWinner === true) this.dialogWatchWinner = false;
      BackendService.lastWinnerGiveawayNoPoints({
        id: this.$route.params.id,
      }).then((data) => {
        clearInterval(this.intervalId);
        console.log(data);
        this.winner = data.data.winner;
        this.dialogWatchWinner = true;
      });
    },
    closeGiveawayWinnerDialog() {
      this.dialogWinner = false;
    },
    closeGiveawayWatchWinnerDialog() {
      this.dialogWatchWinner = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        // console.log(this.editedItem);
        BackendService.saveGiveawayNoPoints(
          this.editedItem,
        ).then((data) => {
          this.giveaways = data.data;
        }).catch(() => {
          alert('nö!');
        }).finally(() => {
          this.saving = false;
          this.close();
        });
      }
    },
  },
};
</script>
