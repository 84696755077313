<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" class="offset-md-3 text-center">
        <div class="custom-container4 pb-5 ">
        <v-row align="center" justify="center">
          <v-col cols="auto" justify="center">
            <v-img
              class="text-center"
              max-width="700"
              src="Thofu_Bot_Funktionsliste.png"
            ></v-img>
          </v-col>
        </v-row>
        </div>
        <div
          v-if="!hidePaypalButton"
        >
          <div
            class="custom-container4 text-center"
            v-if="!paymentDone"
          >
            <h2>Jetzt 30 Tage kostenlos testen!</h2>
            <h4>Nach den 30 Tagen dann 7,50€ monatlich.</h4>
            <v-checkbox
              v-model="termsAccepted"
            >
              <template slot="label"><a href="/agb">AGB</a>  &nbsp;akzeptiert</template>
            </v-checkbox>
            <div :class="{ deactivated: !termsAccepted }">
              <div
                class="ml-9 mt-4"
                id="paypal-button-container-P-7D5329298P4840457MX5WGCA"></div>
              <stripe-buy-button
                buy-button-id="buy_btn_1PTVcuRoJt23Kd2idAidNq0W"
                :publishable-key="'pk_live_51PQzXSRoJt23Kd2i8AWwvVMIkt7fyKyAuomayJBEcpYCUOx2I'
                + 'Vb3Kukzint7qeXWbAjZ1bFtJhGcdkdgTPmaKKAP00NzuiAOLS'"
              >
              </stripe-buy-button>
            </div>
          </div>
          <div class="custom-container4 text-center" v-else>
            <h2>Danke!</h2>
            <h4>Prüf mal deine Mails!</h4>
          </div>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import BackendService from '@/services/BackendService';

export default {
  name: 'PricingView',

  data: () => ({
    paymentDone: false,
    termsAccepted: false,
  }),
  components: {
  },
  computed: {
    hidePaypalButton() {
      return this.$store.state.user_perm && this.$store.state.user_perm >= 4;
    },
  },
  methods: {
    loadPaymentButtons() {
    },
  },
  mounted() {
    if (!this.hidePaypalButton) {
      paypal.Buttons({ // eslint-disable-line
        style: {
          shape: 'rect',
          color: 'white',
          layout: 'vertical',
          label: 'subscribe',
        },
        createSubscription: (data, actions) => actions.subscription.create({
          plan_id: 'P-7D5329298P4840457MX5WGCA',
        }),
        onApprove: (data) => {
          BackendService.sendPaymentConfirmation({ id: data.subscriptionID });
          this.paymentDone = true;
        },
        onClick: () => this.termsAccepted,
      }).render('#paypal-button-container-P-7D5329298P4840457MX5WGCA');
    }
  },
};
</script>
<style scoped>
  .deactivated {
    opacity: 0.3;
    pointer-events: none;
  }
</style>
