<template>
  <div ref="wrapper" class="wrapper">
    <div style="background-color: blue"
       :style="{
          width: `${width}px`,
          height: `${height}px`,
          zoom: zoom,
        }"><h1>{{ zoom }}</h1></div>
  </div>
</template>

<script>
export default {
  name: 'OverlayComponent',
  props: {
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isMounted: false,
      zoom: 0,
    };
  },
  computed: {
    xxx() {
      if (!this.isMounted) {
        return {};
      }
      // console.log(this.$refs);
      return this.$refs.wrapper.getBoundingClientRect();
    },
  },
  methods: {
    calcZoom() {
      const boundingClientRect = this.$refs.wrapper.getBoundingClientRect();
      const zoomWidth = boundingClientRect.width / this.width;
      const zoomHeight = boundingClientRect.height / this.height;
      this.zoom = Math.min(zoomWidth, zoomHeight);
      return this.zoom;
    },
  },
  mounted() {
    this.calcZoom();
  },
  created() {
    window.addEventListener('resize', this.calcZoom);
  },
  destroyed() {
    window.removeEventListener('resize', this.calcZoom);
  },
};
</script>

<style scoped>
.wrapper {
  height: 100%;
}
</style>
