<template>
  <v-container>
    <v-row>
      <v-col cols="11">
        <h1 class="text-center">Commands</h1>
      </v-col>
      <v-col cols="1">

      </v-col>
    </v-row>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="$store.state.user_perm >= 8 ? commands : filteredCommands"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:[`item.userCooldown`]="{ item }">
        {{ getUserCooldownText(item.cooldowntyp, item.userCooldown) }}
      </template>
      <template v-slot:[`item.cooldown`]="{ item }">
        {{ getGlobalCooldownText(item.cooldowntyp, item.cooldown) }}
      </template>
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          max-width="80vw"
        >
          <v-card>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                      disabled
                    ></v-text-field>
                  </v-col>

                    <v-col
                    cols="6"
                    >
                    <v-select
                    v-model="editedItem.perm"
                    item-value="Test"
                    :items="Rang"
                    label="Rang"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                      <v-checkbox
                        v-model="editedItem.cooldowntyp"
                        label="Global Cooldown"
                        :disabled="editedItem.cooldowntyp === 2"
                        :true-value="1"
                        :false-value="3"
                      ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="editedItem.cooldown"
                      label="Globale Cooldown in s"
                      :disabled="editedItem.cooldowntyp === 2"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-checkbox
                      v-model="editedItem.cooldowntyp"
                      label="User Cooldown"
                      :disabled="editedItem.cooldowntyp === 1"
                      :true-value="2"
                      :false-value="3"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="editedItem.userCooldown"
                      label="User Cooldown in s"
                      :disabled="editedItem.cooldowntyp === 1"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.command"
                      label="Command"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="chipGroup"
                  >
                    <v-label>Alias</v-label>
                    <v-chip-group
                      multiple
                    >
                      <v-chip
                        v-for="tag in editedItem.alias"
                        :key="tag"
                        close
                        close-icon="mdi-delete"
                        @click="editAlias(tag)"
                        @click:close="removeAlias(tag)"
                      >
                        <v-text-field
                          v-if="editedAlias === tag"
                          v-model="editedAliasValue"
                          @keydown="checkEditAliasKey"
                          @change="saveEditAlias"
                        ></v-text-field>
                        <span v-else>
                        {{ tag }}
                        </span>
                      </v-chip>
                      <v-btn
                        fab
                        small
                        dark
                        @click="newAlias"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          :disabled="$store.state.user_perm < 4 || $store.state.user_perm < item.user_perm"
        >
          mdi-pencil
        </v-icon>
          <v-icon
            v-if="$store.state.user_perm >= 8"
            small
            class="mr-2"
            :color="item.visible === 0 ? 'error' : 'success'"
            @click="saveVisibleState(item)"
          >
            {{ item.visible === 1 ? 'mdi-lock-open-variant' : 'mdi-lock' }}
          </v-icon>
        <v-switch
          v-model="item.active"
          color="success"
          :true-value="1"
          :false-value="0"
          hide-details
          @change="saveSwitchState(item)"
          :disabled="$store.state.user_perm < 4 || $store.state.user_perm < item.user_perm"
          class="align-icons"
        ></v-switch>
        </div>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'CommandsView',

  data: () => ({
    search: '',
    dialog: false,
    commands: [],
    saving: false,
    Rang: ['Everyone', 'Sub', 'Vip', 'Mod', 'Editor', 'Admin', 'Streamer'],
    headers: [
      { text: 'Name', value: 'name', align: 'center' },
      { text: 'Perm', value: 'perm', align: 'center' },
      { text: 'Command', value: 'command', align: 'center' },
      { text: 'Alias', value: 'alias', align: 'center' },
      { text: 'User Cooldown in s', value: 'userCooldown', align: 'center' },
      { text: 'Global Cooldown in s', value: 'cooldown', align: 'center' },
      { text: 'Description', value: 'description', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
    editedAlias: null,
    editedAliasValue: '',
  }),
  components: {
  },
  mounted() {
    // console.log('load Commands!');
    BackendService.loadCommand(this.$store.state.db).then((data) => {
      // console.log(data.data);
      this.commands = data.data;
    });
  },
  computed: {
    filteredCommands() {
      return this.commands.filter((item1) => item1.visible === 1);
    },
  },
  methods: {
    getUserCooldownText(cooldownType, userCooldown) {
      switch (cooldownType) {
        case 2:
          return userCooldown;
        default:
          return '-';
      }
    },
    getGlobalCooldownText(cooldownType, globalCooldown) {
      switch (cooldownType) {
        case 1:
          return globalCooldown;
        default:
          return '-';
      }
    },
    newAlias() {
      this.editedItem.alias.push('');
      this.editAlias('');
    },
    editItem(item) {
      this.editedIndex = this.commands.indexOf(item);
      this.editedItem = { ...item };
      this.editedItem.alias = item.alias.slice();
      this.editedItem.active = item.active;
      this.dialog = true;
    },
    removeAlias(alias) {
      this.editedItem.alias = this.editedItem.alias.filter((item) => item !== alias);
    },
    editAlias(alias) {
      this.editedAlias = alias;
      this.editedAliasValue = alias;
    },
    saveEditAlias() {
      const idx = this.editedItem.alias.indexOf(this.editedAlias);
      this.editedItem.alias[idx] = this.editedAliasValue;
      this.editedAlias = null;
    },
    checkEditAliasKey(e) {
      if (e.keyCode === 13) {
        this.saveEditAlias();
      } else if (e.keyCode === 27) {
        this.editedAlias = null;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.editedAlias = null;
      });
    },

    save() {
      this.saving = true;
      BackendService.saveCommand({
        id: this.editedItem.id,
        perm: this.editedItem.perm,
        command: this.editedItem.command,
        alias: this.editedItem.alias,
        cooldown: this.editedItem.cooldown,
        userCooldown: this.editedItem.userCooldown,
        cooldowntyp: this.editedItem.cooldowntyp,
        description: this.editedItem.description,
        db: this.$store.state.db,
      }).then((data) => {
        this.commands = data.data;
      }).catch(() => {
        alert('nö!');
      }).finally(() => {
        this.saving = false;
        this.close();
      });
    },
    saveSwitchState(item) {
      if (item.active) {
        // Switch ist auf "ON"
        // Führe Aktion aus...
        // console.log(item.id);
        // console.log('AN!');
        this.saveSwitchStateDB(item.id, 1);
      } else {
        // Switch ist auf "OFF"
        // Führe andere Aktion aus...
        // console.log(item.id);
        // console.log('AUS!');
        this.saveSwitchStateDB(item.id, 0);
      }
    },
    saveSwitchStateDB(id, state) {
      // console.log('SAVE');
      // console.log(id);
      // console.log(state);
      BackendService.saveSwitchStateCommands({
        active: state,
        id1: id,
        db: this.$store.state.db,
      }).then((data) => {
        this.commands = data.data;
      }).catch(() => {
        alert('nö!');
      });
    },
    saveVisibleState(item) {
      if (item.visible) {
        this.saveVisibleStateDB(item.id, 0);
      } else {
        this.saveVisibleStateDB(item.id, 1);
      }
    },
    saveVisibleStateDB(id, state) {
      BackendService.saveVisibleStateCommands({
        visible: state,
        id1: id,
        db: this.$store.state.db,
      }).then((data) => {
        this.commands = data.data;
      }).catch(() => {
        alert('nö!');
      });
    },
  },
};
</script>

<style>
.align-icons {
  align-self: center; /* Vertikal zentrieren */
  margin-bottom: 15px;
}
</style>
